import React, { useEffect, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { DestinationSuggestion } from "main/javascripts/models/DestinationSuggestion";
import { FlightOriginDestinationSuggestion } from "main/javascripts/models/FlightOriginDestinationSuggestion";
import { css } from "@emotion/react";
import { Icon } from "main/javascripts/components/atoms/Icon";
import { SuggestionItem } from "./SuggestionItem";
import { SuggestionItemSkeleton } from "./SuggestionItemSkeleton";
import {
  backgroundColor,
  borderColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  fontSize,
  fontWeight,
} from "main/javascripts/styles/base/typographyStyle";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";

export interface IProps {
  namespace?: string;
  name: string;
  suggestions: DestinationSuggestion[] | FlightOriginDestinationSuggestion[];
  placeholder?: string;
  isLoading?: boolean;
  onRequest: any;
  onSelected(
    suggestion: DestinationSuggestion | FlightOriginDestinationSuggestion
  ): void;
  onFocus?(): any;
  onBlur?(): any;
  onClear?: any;
  suggestOptions?: any;
  suggestIconType?: string;
}

// 現在モバイル専用
export const SuggestionPicker: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    name,
    suggestions,
    placeholder,
    isLoading,
    onRequest,
    onFocus,
    onBlur,
    onSelected,
    onClear,
    suggestOptions,
    suggestIconType,
  } = props;

  const { register, setValue, control } = useFormContext();
  const { onChange, ...registerProps } = register(name);
  const currentValue = useWatch({
    control,
    name: name,
  });

  const suggestionBlockRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (visualViewport) {
      visualViewport.addEventListener("resize", onResizeWindow);
    }
    return () => {
      if (visualViewport) {
        visualViewport.removeEventListener("resize", onResizeWindow);
      }
    };
  }, []);

  const onResizeWindow: () => void = () => {
    if (suggestionBlockRef.current && visualViewport) {
      suggestionBlockRef.current.style.height = `calc(${window.visualViewport.height}px - 7rem - ${space.atom})`;
    }
  };

  const onDestinationChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value) {
      onRequest({
        text: value,
        ...(suggestOptions || {}),
      });
    }
    if (!value && onClear) {
      onClear();
    }
  };

  const onSuggestionSelected = (suggestion): void => {
    onSelected(suggestion);
  };

  const onClearInput: (event: React.FormEvent<any>) => void = (
    event: React.FormEvent<any>
  ) => {
    event.preventDefault();
    setValue(name, "");
    if (onClear) {
      onClear();
    }
  };

  // react hook formのonChangeと合成
  const onInputChange = (e): void => {
    void onChange(e);
    onDestinationChange(e);
  };

  const onInputBlur = (e): void => {
    if (registerProps.onBlur) {
      registerProps.onBlur(e);
    }
    if (onBlur) {
      onBlur();
    }
  };

  const renderItems = () => {
    if (isLoading) {
      return (
        <>
          <SuggestionItemSkeleton />
          <SuggestionItemSkeleton />
          <SuggestionItemSkeleton />
          <SuggestionItemSkeleton />
          <SuggestionItemSkeleton />
        </>
      );
    } else {
      return suggestions.map((suggestion) => (
        <SuggestionItem
          key={suggestion.id}
          suggestion={suggestion}
          onSelected={onSuggestionSelected}
          suggestIconType={suggestIconType}
        />
      ));
    }
  };

  return (
    <div css={blockStyle}>
      <div css={inputBlockStyle}>
        <input
          css={inputStyle}
          name={name}
          placeholder={placeholder}
          {...registerProps}
          onChange={onInputChange}
          onBlur={onInputBlur}
          onFocus={onFocus}
        />
        <div css={searchIconStyle}>
          <Icon styleKey="search" />
        </div>
        {currentValue ? (
          <button css={clearButtonStyle} onClick={onClearInput} type="button">
            <div css={clearButtonIconStyle}>
              <Icon styleKey="close" />
            </div>
          </button>
        ) : (
          ""
        )}
      </div>
      <div css={suggestionBlockStyle} ref={suggestionBlockRef}>
        {renderItems()}
      </div>
    </div>
  );
};

const inputBlockStyle = css`
  position: relative;
  padding: ${space.atom2x};
  border-bottom: 1px solid ${borderColor.primaryLightColor};
`;
const inputStyle = css`
  font-size: ${fontSize.form};
  font-weight: ${fontWeight.medium};
  color: ${textColor.primaryDarkColor};
  padding: 0 2.5rem 0 2.5rem;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  height: 3rem;
  border: 0;
  appearance: none;
  background-color: ${backgroundColor.ground};
  border-radius: ${borderRadius.normal};
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${textColor.disabledColor};
  }
`;
// const inputWithErrorStyle = css`
//   border-bottom-color: ${accentColor.alertColor};
// `;
const blockStyle = css`
  position: relative;
  height: 100%;
`;
const clearButtonStyle = css`
  position: absolute;
  top: 50%;
  right: ${space.atom2x};
  background: none;
  border: 0;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  cursor: pointer;
  outline: none;
  opacity: 0.6;
  transition: opacity 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 1;
  }
  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    background-color: ${borderColor.secondaryLightColor};
    border-radius: 50%;
  }
`;
const clearButtonIconStyle = css`
  display: flex;
  align-items: center;
  position: relative;
  font-size: ${fontSize.caption};
  color: ${textColor.primaryDarkColor};
`;
const searchIconStyle = css`
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 1.875rem;
  font-size: ${fontSize.body};
  color: ${textColor.primaryDarkColor};
  transform: translateY(-50%);
`;
const suggestionBlockStyle = css`
  height: calc(100% - 3rem - ${space.atom});
  overflow: auto;
  padding: ${space.atom2x} 0;
  box-sizing: border-box;
`;
