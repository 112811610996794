import React from "react";
import { css } from "@emotion/react";
import { DestinationTypes } from "main/javascripts/constants/DestinationTypes";
import { Icon } from "main/javascripts/components/atoms/Icon";
import { IIconFontStyle } from "main/javascripts/styles/fontStyle";
import { textColor } from "main/javascripts/styles/base/colorStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  fontSize,
  fontWeight,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import { ISvgComponent, SvgIcon } from "../icon/SvgIcon";

export interface IProps {
  suggestion: any; // TODO type
  onSelected(suggestion: any): any;
  suggestIconType?: string;
}

export const SuggestionItem: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const { suggestion, onSelected, suggestIconType } = props;

  const onItemSelected = () => {
    onSelected(suggestion);
  };

  let iconType: keyof IIconFontStyle = "pin";
  let svgIconType: keyof ISvgComponent;
  let isSvgIcon = false;

  const svgIconTypes = ["cruise"];

  if (suggestIconType) {
    if (svgIconTypes.includes(suggestIconType)) {
      svgIconType = suggestIconType as keyof ISvgComponent;
      isSvgIcon = true;
    } else {
      iconType = suggestIconType as keyof IIconFontStyle;
    }
  } else {
    switch (suggestion.destinationType) {
      case DestinationTypes.region:
        iconType = "city";
        break;
      case DestinationTypes.hotel:
        iconType = "hotel";
        break;
      case DestinationTypes.airport:
        iconType = "airplane";
        break;
      default:
    }
  }

  return (
    <div css={suggestStyle} onClick={onItemSelected}>
      <div css={iconStyle}>
        {isSvgIcon ? (
          <SvgIcon name={svgIconType} />
        ) : (
          <Icon styleKey={iconType} />
        )}
      </div>
      <div css={suggestionValueBlockStyle}>
        <div css={suggestionValueStyle}>{suggestion.name}</div>
        {suggestion.label && (
          <div css={suggestionLabelStyle}>{suggestion.label}</div>
        )}
      </div>
    </div>
  );
};

const suggestStyle = css`
  position: relative;
  margin: 0;
  padding: ${space.atom1_5x} ${space.atom2x} ${space.atom1_5x} 4rem;
  cursor: pointer;
  color: ${textColor.primaryDarkColor};
  &:hover {
    background-color: #f5f5f5;
  }
`;
const iconStyle = css`
  position: absolute;
  top: 0.625rem;
  left: 1.5rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  color: ${textColor.secondaryDarkColor};
  opacity: 0.3;
  svg {
    width: 100%;
    height: auto;
    path {
      fill: ${textColor.secondaryDarkColor};
    }
  }
`;
const suggestionValueBlockStyle = css`
  display: flex;
`;
const suggestionValueStyle = css`
  flex: 1;
  width: 60%;
  font-size: ${fontSize.body};
  font-weight: ${fontWeight.bold};
  line-height: ${lineHeight.body2};
`;
const suggestionLabelStyle = css`
  display: block;
  width: 40%;
  padding: 0.2rem 0 0 ${space.atom};
  font-size: ${fontSize.caption};
  color: ${textColor.disabledColor};
  line-height: ${lineHeight.caption3};
  text-align: right;
  box-sizing: border-box;
`;
