import React, { ReactElement } from "react";
import { css } from "@emotion/react";
import Cruise from "./svg/cruise.svg";

export interface ISvgComponent {
  cruise: ReactElement;
}

interface IProps {
  name: keyof ISvgComponent;
  size?: string;
  color?: string;
}

export const SvgIcon: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  const { name, size = "1.5rem", color = "#fff" } = props;
  const iconStyles = css`
    ${iconStyle};
    width: ${size};
    path {
      fill: ${color};
    }
  `;

  const svgComponent: ISvgComponent = {
    cruise: <Cruise />,
  };

  return <div css={iconStyles}>{svgComponent[name]}</div>;
};

const iconStyle = css`
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`;
