import React from "react";
import { css } from "@emotion/react";
import {
  accentColor,
  backgroundColor,
  borderColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  fontSize,
  fontWeight,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import { ILabelProps, Label } from "../form/Label";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";
import {
  IBorderColorStyle,
  IFontSizeStyle,
} from "main/javascripts/styles/base/formStyle";
import { InputError } from "../form/InputError";
import { FieldError } from "react-hook-form";

export interface IProps {
  namespace?: string;
  name: string;
  label: ILabelProps;
  longName?: string;
  placeholder?: string;
  onClick: any;
  borderColorStyleKey?: keyof IBorderColorStyle;
  fontSizeStyleKey?: keyof IFontSizeStyle;
  error?: FieldError;
}

export const SuggestionFieldButton: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    namespace,
    name,
    label,
    longName,
    placeholder,
    onClick,
    borderColorStyleKey = "none",
    fontSizeStyleKey = "default",
    error,
  } = props;

  const buttonStyles = longName ? buttonStyle : buttonPlaceholderStyle;

  return (
    <div css={blockStyle}>
      <Label {...label} />
      <button
        type="button"
        css={[
          buttonStyles,
          buttonBorderColorStyle[borderColorStyleKey],
          buttonFontSizeStyle[fontSizeStyleKey],
        ]}
        onClick={onClick}
      >
        {longName || placeholder}
      </button>
      {error && (
        <InputError
          namespace={namespace}
          errorMessage={error.message}
          label={name}
        />
      )}
    </div>
  );
};

const blockStyle = css`
  padding: calc(${space.atom} - (${lineHeight.body1} - ${fontSize.body}) / 2)
    ${space.atom} ${space.atom};
`;
const buttonStyle = css`
  font-size: ${fontSize.form};
  font-weight: ${fontWeight.regular};
  color: ${textColor.primaryDarkColor};
  caret-color: ${accentColor.primaryColor};
  padding: 0.125rem ${space.atom1_5x} 0;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  height: 40px;
  border: 1px solid;
  border-radius: ${borderRadius.normal};
  background-color: ${backgroundColor.primaryLightColor};
  text-align: left;
  appearance: none;
  &:focus {
    outline: none;
  }
`;
const buttonPlaceholderStyle = css`
  ${buttonStyle};
  color: ${textColor.disabledColor};
`;

const buttonBorderColorStyle: IBorderColorStyle = {
  none: css`
    border-color: white;
  `,
  primary: css`
    border-color: ${borderColor.primaryDarkColor};
  `,
  secondary: css`
    border-color: ${borderColor.secondaryLightColor};
  `,
};

const buttonFontSizeStyle: IFontSizeStyle = {
  default: css`
    font-size: ${fontSize.form};
  `,
  small: css`
    font-size: ${fontSize.body};
  `,
};
