import React, { useRef } from "react";
import Autosuggest from "react-autosuggest";
import { FieldError, useFormContext } from "react-hook-form";
import { DestinationSuggestion } from "main/javascripts/models/DestinationSuggestion";
import { FlightOriginDestinationSuggestion } from "main/javascripts/models/FlightOriginDestinationSuggestion";
import { Label, ILabelProps } from "main/javascripts/components/form/Label";
import { InputError } from "main/javascripts/components/form/InputError";
import { css, ClassNames } from "@emotion/react";
import { DestinationTypes } from "main/javascripts/constants/DestinationTypes";
import { Icon } from "main/javascripts/components/atoms/Icon";
import { IIconFontStyle } from "main/javascripts/styles/fontStyle";
import {
  accentColor,
  backgroundColor,
  borderColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  fontSize,
  fontWeight,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";
import {
  IBorderColorStyle,
  IFontSizeStyle,
} from "main/javascripts/styles/base/formStyle";
import { Suggestion } from "../../types/suggestion";
import { Cruise } from "../../types/cruise";
import { ISvgComponent, SvgIcon } from "../icon/SvgIcon";

export interface IProps {
  namespace?: string;
  label: ILabelProps;
  input: any;
  fetchDestinationSuggest: any;
  onSelected(
    suggestion:
      | DestinationSuggestion
      | FlightOriginDestinationSuggestion
      | Cruise.VesselBrand
      | Suggestion
  ): void;
  suggestions:
    | DestinationSuggestion[]
    | FlightOriginDestinationSuggestion[]
    | Cruise.VesselBrand[]
    | Suggestion[];
  onFocus?(): any;
  onBlur?(): any;
  inputBlockRef?: any;
  suggestOptions?: any;
  onClear?: any;
  error?: FieldError;
  preventClearWhenInputBoxIsBlank?: boolean;
  forceDisplayCloseButton?: boolean;
  disableOnFocusRequest?: boolean;
  alwaysShowSuggestions?: boolean;
  borderColorStyleKey?: keyof IBorderColorStyle;
  fontSizeStyleKey?: keyof IFontSizeStyle;
  suggestIconType?: string;
}

export const InputAutosuggestBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    namespace = "common",
    label,
    input,
    fetchDestinationSuggest,
    suggestions,
    onFocus,
    onBlur,
    onSelected,
    suggestOptions,
    onClear,
    error,
    inputBlockRef,
    preventClearWhenInputBoxIsBlank,
    forceDisplayCloseButton,
    disableOnFocusRequest = false,
    alwaysShowSuggestions = false,
    borderColorStyleKey = "none",
    fontSizeStyleKey = "default",
    suggestIconType,
  } = props;

  const inputRef = useRef<any>();

  const { register } = useFormContext();
  const { onChange, ...registerProps } = register(input.name);

  const onChangeDestination: (
    event: React.ChangeEvent<HTMLInputElement>,
    params?: Autosuggest.ChangeEvent
  ) => void = (
    event: React.ChangeEvent<HTMLInputElement>,
    params?: Autosuggest.ChangeEvent
  ): void => {
    const { value } = event.target;
    if (input.onChange) {
      input.onChange(event);
    }
    onSuggestionsFetchRequested({ value, reason: "input-changed" });
    if (
      !preventClearWhenInputBoxIsBlank &&
      params &&
      params.newValue === "" &&
      onClear
    ) {
      onClear();
    }
  };

  const onSuggestionSelected: (
    event: React.ChangeEvent<HTMLInputElement>,
    data: Autosuggest.SuggestionSelectedEventData<any>
  ) => void = (
    _: React.ChangeEvent<HTMLInputElement>,
    data: Autosuggest.SuggestionSelectedEventData<any>
  ): void => {
    if (input.onChange) {
      input.onChange(data.suggestion.longName);
    }
    onSelected(data.suggestion);
  };

  const getSuggestionValue: (suggestion: any) => any = (suggestion) => {
    return suggestion.name;
  };

  const renderSuggestion: (
    suggestion: any,
    params: Autosuggest.RenderSuggestionParams
  ) => React.ReactElement = (suggestion, _) => {
    let iconType: keyof IIconFontStyle = "pin";
    let svgIconType: keyof ISvgComponent;
    let isSvgIcon = false;

    const svgIconTypes = ["cruise"];

    if (suggestIconType) {
      if (svgIconTypes.includes(suggestIconType)) {
        svgIconType = suggestIconType as keyof ISvgComponent;
        isSvgIcon = true;
      } else {
        iconType = suggestIconType as keyof IIconFontStyle;
      }
    } else {
      switch (suggestion.destinationType) {
        case DestinationTypes.region:
          iconType = "city";
          break;
        case DestinationTypes.hotel:
          iconType = "hotel";
          break;
        case DestinationTypes.airport:
          iconType = "airplane";
          break;
        default:
      }
    }

    return (
      <div css={suggestStyle}>
        <div css={iconStyle}>
          {isSvgIcon ? (
            <SvgIcon name={svgIconType} />
          ) : (
            <Icon styleKey={iconType} />
          )}
        </div>
        <div css={suggestionValueBlockStyle}>
          <div css={suggestionValueStyle}>{suggestion.name}</div>
          {suggestion.label && (
            <div css={suggestionLabelStyle}>{suggestion.label}</div>
          )}
        </div>
      </div>
    );
  };

  const onSuggestionsFetchRequested: (
    request: Autosuggest.SuggestionsFetchRequestedParams
  ) => void = (request) => {
    if (!request.value) {
      return;
    }
    if (
      request.reason !== "input-changed" &&
      (disableOnFocusRequest || request.reason !== "input-focused")
    ) {
      return;
    }
    fetchDestinationSuggest({
      text: request.value,
      ...(suggestOptions || {}),
    });
  };

  const onSuggestionsClearRequested: () => void = () => {
    console.log("clear");
  };

  const onClearInput: (event: React.FormEvent<any>) => void = (
    event: React.FormEvent<any>
  ) => {
    event.preventDefault();
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    if (onClear) {
      onClear();
    }
  };

  // react hook formのonChangeと合成
  const onInputChange = (e): void => {
    void onChange(e);
    onChangeDestination(e);
  };

  const renderInputComponent: (inputProps: any) => React.ReactElement = (
    inputProps: any
  ) => {
    // デフォルトではreact-autosuggestとreact hook formの両方でrefを登録する必要があるため
    // react-autosuggestのfocusInputOnSuggestionClickをfalseにして
    // refを不要にする
    // もしfocusInputOnSuggestionClickが必要な場合はrefを合成する
    // https://github.com/moroshko/react-autosuggest/issues/646
    // https://github.com/facebook/react/issues/13029#issuecomment-497641073

    // react hook formとreact-autosuggestのonBlurと合成
    // 両方にonBlurが登録されており
    // 合成しないとautosuggestのドロップダウンがエリア外がクリックされても閉じなくなる
    const onInputBlur = (e): void => {
      if (inputProps.onBlur) {
        inputProps.onBlur(e);
      }
      if (registerProps.onBlur) {
        registerProps.onBlur(e);
      }
      if (onBlur) {
        onBlur();
      }
    };

    return (
      <>
        <input
          {...inputProps}
          {...registerProps}
          onChange={onInputChange}
          onBlur={onInputBlur}
          // spreadでkeyを渡すとwarningが発生するため直接指定する
          key={inputProps.key}
        />
        {forceDisplayCloseButton || inputProps.value ? (
          <button css={clearButtonStyle} onClick={onClearInput} type="button">
            <Icon styleKey="close" />
          </button>
        ) : (
          ""
        )}
      </>
    );
  };

  const defaultShouldRenderSuggestions = (value) => value.trim().length > 0;
  const shouldAlwaysRenderSuggestions = () => {
    return true;
  };
  const shouldRenderSuggestions = alwaysShowSuggestions
    ? shouldAlwaysRenderSuggestions
    : defaultShouldRenderSuggestions;

  const hasError = !!error;

  return (
    <div css={blockStyle} ref={inputBlockRef}>
      <Label {...label} />
      <ClassNames>
        {({ css }) => {
          const theme: Autosuggest.Theme = {
            container: css(inputContainerStyle),
            suggestionsContainer: css(suggestionsContainerStyle),
            suggestionsContainerOpen: css(suggestionsContainerOpenStyle),
            suggestionsList: css(suggestionsListStyle),
            suggestion: css(suggestBlockStyle),
          };
          const InputProps: Autosuggest.InputProps<void> = {
            ...input,
            onChange: onInputChange,
            onFocus: onFocus,
            className: hasError
              ? css(
                  inputWithErrorStyle,
                  inputStyle,
                  inputBorderColorStyle[borderColorStyleKey],
                  inputFontSizeStyle[fontSizeStyleKey]
                )
              : css(
                  inputStyle,
                  inputBorderColorStyle[borderColorStyleKey],
                  inputFontSizeStyle[fontSizeStyleKey]
                ),
            autoComplete: "off",
            autoCorrect: "off",
          };

          // TODO: InputErrorStyle
          return (
            <>
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                inputProps={InputProps}
                theme={theme}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={onSuggestionSelected}
                focusInputOnSuggestionClick={false} // suggestを選択したらblur
                renderInputComponent={renderInputComponent}
                shouldRenderSuggestions={shouldRenderSuggestions}
                ref={inputRef}
              />
              {error && (
                <InputError
                  namespace={namespace}
                  errorMessage={error.message}
                  label={input.name}
                />
              )}
            </>
          );
        }}
      </ClassNames>
    </div>
  );
};

const inputContainerStyle = css`
  position: relative;
`;
const inputStyle = css`
  padding-right: 2rem;
  font-size: ${fontSize.form};
  font-weight: ${fontWeight.regular};
  color: ${textColor.primaryDarkColor};
  caret-color: ${accentColor.primaryColor};
  padding: 0.125rem ${space.atom1_5x} 0;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  height: 40px;
  border: 1px solid;
  border-radius: ${borderRadius.normal};
  appearance: none;
  &:focus {
    outline: none;
    border-bottom-color: ${accentColor.primaryColor};
  }
  &::placeholder {
    color: ${textColor.disabledColor};
  }
`;
const inputWithErrorStyle = css`
  border-bottom-color: ${accentColor.alertColor};
`;
const suggestionsContainerStyle = css`
  display: none;
  box-sizing: border-box;
`;
const suggestionsContainerOpenStyle = css`
  display: block;
  position: absolute;
  top: 37px;
  margin: 0;
  max-height: 600px;
  background-color: white;
  width: 100%;
  z-index: 100;
  box-shadow: 0 6px 30px rgba(40, 40, 40, 0.1);
  border-top: 1px solid ${accentColor.primaryColor};
  overflow: auto;
  // containerがクリックされるとAutosuggestでクラッシュするため
  // クリックを無効化する
  pointer-events: none;
`;
const suggestionsListStyle = css`
  margin: 0;
  padding: 0;
  list-style-type: none;
  // サジェストはクリックできるように有効化する
  pointer-events: auto;
`;
const suggestBlockStyle = css`
  border-bottom: 1px solid ${borderColor.primaryLightColor};
`;
const suggestStyle = css`
  position: relative;
  margin: 0;
  padding: ${space.atom} ${space.atom2x} ${space.atom} 3rem;
  cursor: pointer;
  color: ${textColor.primaryDarkColor};
  &:hover {
    background-color: ${backgroundColor.secondaryLightColor};
  }
`;
const blockStyle = css`
  padding: calc(${space.atom} - (${lineHeight.body1} - ${fontSize.body}) / 2)
    ${space.atom} ${space.atom};
`;
// const inputErrorStyle = css`
//   padding: ${space.atom0_5x} 0 0;
// `;
const iconStyle = css`
  position: absolute;
  top: 0.4375rem;
  left: 0.75rem;
  font-size: 1.5rem;
  vertical-align: middle;
  color: ${textColor.secondaryDarkColor};
  opacity: 0.4;
  svg {
    width: 100%;
    height: auto;
    path {
      fill: ${textColor.secondaryDarkColor};
    }
  }
`;
const suggestionValueBlockStyle = css`
  display: flex;
`;
const suggestionValueStyle = css`
  flex: 1;
  width: 60%;
  font-size: ${fontSize.body};
`;
const suggestionLabelStyle = css`
  display: block;
  width: 40%;
  padding: 0.2rem 0 0 ${space.atom};
  font-size: ${fontSize.caption};
  color: ${textColor.disabledColor};
  line-height: ${lineHeight.caption3};
  text-align: right;
  box-sizing: border-box;
`;
const clearButtonStyle = css`
  position: absolute;
  top: 50%;
  right: 0;
  font-size: ${fontSize.body};
  background: none;
  border: 0;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: ${textColor.secondaryDarkColor};
  outline: none;
  opacity: 0.6;
  transition: opacity 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 1;
  }
`;

const inputBorderColorStyle: IBorderColorStyle = {
  none: css`
    border-color: white;
  `,
  primary: css`
    border-color: ${borderColor.primaryDarkColor};
  `,
  secondary: css`
    border-color: ${borderColor.secondaryLightColor};
  `,
};

const inputFontSizeStyle: IFontSizeStyle = {
  default: css`
    font-size: ${fontSize.form};
  `,
  small: css`
    font-size: ${fontSize.body};
  `,
};
